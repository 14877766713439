import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { arr_horarios_colaborador, arr_horarios_colaborador_disponible, info_usuario_modelo_response } from 'src/model/Catalogos/Colaboradores';
import { Set_reclamar_usuario_horario_modelo_request } from 'src/model/Catalogos/Horarios';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { RespuestaGenerica } from '../generales/RespuestaGenerica';
import { ServiciosRutas } from '../generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from '../shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-colaborador',
  templateUrl: './info-colaborador.component.html',
  styleUrls: ['./info-colaborador.component.css'],
  providers: [ServiceGenerico]
})
export class InfoColaboradorComponent implements OnInit {
  @ViewChild("modalValidaPin", { static: false }) modalValidaPin: TemplateRef<any>;
  @ViewChild("modalMensaje", { static: false }) modalMensaje: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalValPin: NgbModalRef;
  
  formGroupCodigoPin: FormGroup;


  public operacionRespuesta: RespuestaGenerica;
  private modelo_configuracion: ServiciosRutas;
  infoColaborador: info_usuario_modelo_response;
  showInfo: boolean = false;
  listaComidasHoy: arr_horarios_colaborador;
  arrlistaComidasHoy: Array<arr_horarios_colaborador>;
  listaComidasHoyDisponible: arr_horarios_colaborador_disponible;
  arrlistaComidasHoyDisponible: Array<arr_horarios_colaborador_disponible>;
  horarioDisponible: boolean = false;
  _numero_empleado: string;
  banderaComidaDisponible: boolean = false;
  imagenPrevia: string;
  nuevaImagenAvatar: boolean = false;
  public _requiere_pin: boolean;
  public _pin: any;
  public _rel_usuario_horario_id: number;

  mensajeTitulo: string;
  mensaje: string;
  showExitos: boolean;
  showErrors: boolean;
  showAdvertencias: boolean;
  mensajesError: any;
  mensajesExito: any;
  constructor(
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private router: Router
  ) { 
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();

  }

  ngOnInit() {
    this.inicializarFormValidaPin();
  }

  inicializarFormValidaPin() {
    this.formGroupCodigoPin = this.fb.group({
      p_pin: ["", Validators.required],
    });
  }

  getColaboradorInfo() {
    
    if (this._numero_empleado == null || this._numero_empleado == "") {
      this._numero_empleado = String($('#numero_empleado').val());
    }

    if (String(this._numero_empleado).length > 3) {
      this.operacionRespuesta.EstaEjecutando = true;
      this.services
        .HttpGet(
          this.modelo_configuracion.serviciosOperaciones + "/InfoUsuario/GetInfoUsuario?p_numero_empleado="+ this._numero_empleado)
        .subscribe(
          (tempdate) => {
            if (tempdate) {
              this.infoColaborador = tempdate.response as info_usuario_modelo_response;
              this._requiere_pin = this.infoColaborador[0].requiere_pin;
              if (this._requiere_pin == true) {
                this._pin = this.infoColaborador[0].pin;
              }
              this.consultaImagenPerfil(this.infoColaborador[0].numero_empleado);
  
              this.calculaHorario(this.infoColaborador);
              this.showInfo = true;
            } else {
              this._numero_empleado = ""
              this.infoColaborador = new info_usuario_modelo_response;
              this.showInfo = false;
            
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this._numero_empleado = ""
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } 

  }

  calculaHorario(colaborador: info_usuario_modelo_response) {
    this.banderaComidaDisponible = false;
    var now = new Date();
    var nombreDiaHoy = now.toLocaleString('sp-mx', {weekday:'long'});
    var idDiaHoy = now.getDay();
    var horaActual = now.getHours().toString().padStart(2, '0');
    var minutoActual = now.getMinutes().toString().padStart(2, '0');
    var segundoActual = now.getSeconds().toString().padStart(2, '0');

    var horaActualCompleta = [horaActual, minutoActual, segundoActual].join(':')
 
    this.arrlistaComidasHoy = [];
    this.arrlistaComidasHoyDisponible = [];
 
    if (colaborador[0].super_usuario) {
      for (var i = 0; i <= colaborador.length - 1; i++) {
        this.listaComidasHoy = new arr_horarios_colaborador;
        this.listaComidasHoyDisponible = new arr_horarios_colaborador_disponible;
        this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
        this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
        this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
        this.banderaComidaDisponible = true;
        this.listaComidasHoy.estatus_reclamo = 0;

        this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
        this.arrlistaComidasHoy.push(this.listaComidasHoy);

      }

  } else {

    switch(idDiaHoy) {
      case 1: 
        for (var i = 0; i <= colaborador.length - 1; i++ ) {
          this.listaComidasHoy = new arr_horarios_colaborador;
          this.listaComidasHoyDisponible = new arr_horarios_colaborador_disponible;

          //Nuevo Código
        // Si el dia anterior (domingo) tiene fecha fin en lunes debe entrar 
        if (colaborador[i].dia_fin_0 == 1) {
          let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, colaborador[i].h_domingo_i.hours, colaborador[i].h_domingo_i.minutes, colaborador[i].h_domingo_i.seconds);
          let h_time_f = new Date(now.getFullYear(), now.getMonth(), now.getDate() , colaborador[i].h_domingo_f.hours, colaborador[i].h_domingo_f.minutes, colaborador[i].h_domingo_f.seconds);

          if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
            this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
            this.horarioDisponible = true;
            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
            }

            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.listaComidasHoy.estatus_reclamo = 0;
              this.banderaComidaDisponible = true;
              } else {
              this.listaComidasHoy.estatus_reclamo = 1;
            }
          } else {
            if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
              this.listaComidasHoy.estatus_reclamo = 2;
            } else {
              this.listaComidasHoy.estatus_reclamo = 3;
            }
          }
          this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
          this.listaComidasHoy.horario = [colaborador[i].h_domingo_i.hours.toString().padStart(2, '0'), colaborador[i].h_domingo_i.minutes.toString().padStart(2, '0'), colaborador[i].h_domingo_i.seconds.toString().padStart(2, '0')].join(':')
            + " a " + [colaborador[i].h_domingo_f.hours.toString().padStart(2, '0'), colaborador[i].h_domingo_f.minutes.toString().padStart(2, '0'), colaborador[i].h_domingo_f.seconds.toString().padStart(2, '0')].join(':')
            + " hrs.";

          this.arrlistaComidasHoy.push(this.listaComidasHoy);
        } else {
          if (colaborador[i].lunes == true) {

            let _diafin;
            if (colaborador[i].dia_fin_1 == 0) {
              _diafin =  now.getDate() + (6);       
            } else {
              _diafin =  now.getDate() + (colaborador[i].dia_fin_1 - 1);       
            }

            let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate(), colaborador[i].h_lunes_i.hours, colaborador[i].h_lunes_i.minutes, colaborador[i].h_lunes_i.seconds);
            let h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_lunes_f.hours, colaborador[i].h_lunes_f.minutes, colaborador[i].h_lunes_f.seconds);

            if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
              this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
              this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
              this.horarioDisponible = true;
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
              }

              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.listaComidasHoy.estatus_reclamo = 0;
                this.banderaComidaDisponible = true;
                } else {
                this.listaComidasHoy.estatus_reclamo = 1;
              }
            } else {
              if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
                this.listaComidasHoy.estatus_reclamo = 2;
              } else {
                this.listaComidasHoy.estatus_reclamo = 3;
              }
            }
            this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoy.horario = [colaborador[i].h_lunes_i.hours.toString().padStart(2, '0'), colaborador[i].h_lunes_i.minutes.toString().padStart(2, '0'), colaborador[i].h_lunes_i.seconds.toString().padStart(2, '0')].join(':')
              + " a " + [colaborador[i].h_lunes_f.hours.toString().padStart(2, '0'), colaborador[i].h_lunes_f.minutes.toString().padStart(2, '0'), colaborador[i].h_lunes_f.seconds.toString().padStart(2, '0')].join(':')
              + " hrs.";

            this.arrlistaComidasHoy.push(this.listaComidasHoy);
          }
        }
        //Nuevo Código


       
      } 
      break;
      case 2: 
      for (var i = 0; i <= colaborador.length - 1; i++ ) {
        this.listaComidasHoy = new arr_horarios_colaborador;
        this.listaComidasHoyDisponible = new arr_horarios_colaborador_disponible;

            //Nuevo Código
        // Si el dia anterior (lunes) tiene fecha fin en martes debe entrar 
        if (colaborador[i].dia_fin_1 == 2) {
          let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, colaborador[i].h_lunes_i.hours, colaborador[i].h_lunes_i.minutes, colaborador[i].h_lunes_i.seconds);
          let h_time_f = new Date(now.getFullYear(), now.getMonth(), now.getDate() , colaborador[i].h_lunes_f.hours, colaborador[i].h_lunes_f.minutes, colaborador[i].h_lunes_f.seconds);

          if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
            this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
            this.horarioDisponible = true;
            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
            }

            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.listaComidasHoy.estatus_reclamo = 0;
              this.banderaComidaDisponible = true;
              } else {
              this.listaComidasHoy.estatus_reclamo = 1;
            }
          } else {
            if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
              this.listaComidasHoy.estatus_reclamo = 2;
            } else {
              this.listaComidasHoy.estatus_reclamo = 3;
            }
          }
          this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
          this.listaComidasHoy.horario = [colaborador[i].h_lunes_i.hours.toString().padStart(2, '0'), colaborador[i].h_lunes_i.minutes.toString().padStart(2, '0'), colaborador[i].h_lunes_i.seconds.toString().padStart(2, '0')].join(':')
            + " a " + [colaborador[i].h_lunes_f.hours.toString().padStart(2, '0'), colaborador[i].h_lunes_f.minutes.toString().padStart(2, '0'), colaborador[i].h_lunes_f.seconds.toString().padStart(2, '0')].join(':')
            + " hrs.";

          this.arrlistaComidasHoy.push(this.listaComidasHoy);
        } else {
          if (colaborador[i].martes == true) {

            let _diafin;
            if (colaborador[i].dia_fin_2 == 0) {
              _diafin =  now.getDate() + (5);       
            } else {
              _diafin =  now.getDate() + (colaborador[i].dia_fin_2 - 2);       
            }
  
            let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate(), colaborador[i].h_martes_i.hours, colaborador[i].h_martes_i.minutes, colaborador[i].h_martes_i.seconds);
            let h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_martes_f.hours, colaborador[i].h_martes_f.minutes, colaborador[i].h_martes_f.seconds);
  
            if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
              this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
              this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
              this.horarioDisponible = true;
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
              }
  
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.listaComidasHoy.estatus_reclamo = 0;
                this.banderaComidaDisponible = true;
                } else {
                this.listaComidasHoy.estatus_reclamo = 1;
              }
            } else {
              if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
                this.listaComidasHoy.estatus_reclamo = 2;
              } else {
                this.listaComidasHoy.estatus_reclamo = 3;
              }
            }
            this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoy.horario = [colaborador[i].h_martes_i.hours.toString().padStart(2, '0'), colaborador[i].h_martes_i.minutes.toString().padStart(2, '0'), colaborador[i].h_martes_i.seconds.toString().padStart(2, '0')].join(':')
              + " a " + [colaborador[i].h_martes_f.hours.toString().padStart(2, '0'), colaborador[i].h_martes_f.minutes.toString().padStart(2, '0'), colaborador[i].h_martes_f.seconds.toString().padStart(2, '0')].join(':')
              + " hrs.";
  
            this.arrlistaComidasHoy.push(this.listaComidasHoy);
          }
        }
        //Nuevo Código

       
    } 
    break;
      case 3: 
      for (var i = 0; i <= colaborador.length - 1; i++ ) {
        this.listaComidasHoy = new arr_horarios_colaborador;
        this.listaComidasHoyDisponible = new arr_horarios_colaborador_disponible;
  //       let h_time_i;
  //       let h_time_f;
       
               //Nuevo Código
        // Si el dia anterior (martes) tiene fecha fin en miercoles debe entrar 
        if (colaborador[i].dia_fin_2 == 3) {
          let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, colaborador[i].h_martes_i.hours, colaborador[i].h_martes_i.minutes, colaborador[i].h_martes_i.seconds);
          let h_time_f = new Date(now.getFullYear(), now.getMonth(), now.getDate() , colaborador[i].h_martes_f.hours, colaborador[i].h_martes_f.minutes, colaborador[i].h_martes_f.seconds);

          if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
            this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
            this.horarioDisponible = true;
            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
            }

            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.listaComidasHoy.estatus_reclamo = 0;
              this.banderaComidaDisponible = true;
              } else {
              this.listaComidasHoy.estatus_reclamo = 1;
            }
          } else {
            if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
              this.listaComidasHoy.estatus_reclamo = 2;
            } else {
              this.listaComidasHoy.estatus_reclamo = 3;
            }
          }
          this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
          this.listaComidasHoy.horario = [colaborador[i].h_martes_i.hours.toString().padStart(2, '0'), colaborador[i].h_martes_i.minutes.toString().padStart(2, '0'), colaborador[i].h_martes_i.seconds.toString().padStart(2, '0')].join(':')
            + " a " + [colaborador[i].h_martes_f.hours.toString().padStart(2, '0'), colaborador[i].h_martes_f.minutes.toString().padStart(2, '0'), colaborador[i].h_martes_f.seconds.toString().padStart(2, '0')].join(':')
            + " hrs.";

          this.arrlistaComidasHoy.push(this.listaComidasHoy);
        } else {
          if (colaborador[i].miercoles == true) {

            let _diafin;
          if (colaborador[i].dia_fin_3 == 0) {
            _diafin =  now.getDate() + (4);       
          } else {
            _diafin =  now.getDate() + (colaborador[i].dia_fin_3 - 3);       
          }
  
            let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate(), colaborador[i].h_miercoles_i.hours, colaborador[i].h_miercoles_i.minutes, colaborador[i].h_miercoles_i.seconds);
            let h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_miercoles_f.hours, colaborador[i].h_miercoles_f.minutes, colaborador[i].h_miercoles_f.seconds);
  
            if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
              this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
              this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
              this.horarioDisponible = true;
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
              }
  
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.listaComidasHoy.estatus_reclamo = 0;
                this.banderaComidaDisponible = true;
                } else {
                this.listaComidasHoy.estatus_reclamo = 1;
              }
            } else {
              if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
                this.listaComidasHoy.estatus_reclamo = 2;
              } else {
                this.listaComidasHoy.estatus_reclamo = 3;
              }
            }
            this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoy.horario = [colaborador[i].h_miercoles_i.hours.toString().padStart(2, '0'), colaborador[i].h_miercoles_i.minutes.toString().padStart(2, '0'), colaborador[i].h_miercoles_i.seconds.toString().padStart(2, '0')].join(':')
              + " a " + [colaborador[i].h_miercoles_f.hours.toString().padStart(2, '0'), colaborador[i].h_miercoles_f.minutes.toString().padStart(2, '0'), colaborador[i].h_miercoles_f.seconds.toString().padStart(2, '0')].join(':')
              + " hrs.";
  
            this.arrlistaComidasHoy.push(this.listaComidasHoy);
          }
        }
        //Nuevo Código

  //       if (parseInt(horaActual) >= 0 && parseInt(horaActual) <= 4  && colaborador[i].miercoles == false) {
  //         idDiaHoy = idDiaHoy - 1;
  //         colaborador[i].miercoles = true;
  //          h_time_i = new Date(now.getFullYear(), now.getMonth(), idDiaHoy, colaborador[i].h_martes_i.hours, colaborador[i].h_martes_i.minutes, colaborador[i].h_martes_i.seconds);
  //         } else {
  //         idDiaHoy = now.getDate();
  //          h_time_i = new Date(now.getFullYear(), now.getMonth(), idDiaHoy, colaborador[i].h_miercoles_i.hours, colaborador[i].h_miercoles_i.minutes, colaborador[i].h_miercoles_i.seconds);
  //       } 

  //       if (colaborador[i].miercoles == true) {

  //         let _diafin;
  //         if (colaborador[i].dia_fin_3 == 0) {
  //           _diafin =  now.getDate() + (4);       
  //         } else {
  //           _diafin =  now.getDate() + (colaborador[i].dia_fin_3 - 3);       
  //         }
          
  //         if (parseInt(horaActual) >= 0 && parseInt(horaActual) <= 4) {
  //           h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_martes_f.hours, colaborador[i].h_martes_f.minutes, colaborador[i].h_martes_f.seconds);
  //         } else {
  //           h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_miercoles_f.hours, colaborador[i].h_miercoles_f.minutes, colaborador[i].h_miercoles_f.seconds);
  //           }

  //         // let h_time_i = new Date(now.getFullYear(), now.getMonth(), idDiaHoy, colaborador[i].h_miercoles_i.hours, colaborador[i].h_miercoles_i.minutes, colaborador[i].h_miercoles_i.seconds);
  //         // let h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_miercoles_f.hours, colaborador[i].h_miercoles_f.minutes, colaborador[i].h_miercoles_f.seconds);

  //         if (now.getTime() > h_time_i.getTime() && now.getTime() <= h_time_f.getTime()) {
  //           this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
  //           this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
  //           this.horarioDisponible = true;
  //           if (colaborador[i].tbl_comida_reclamada_id == null) {
  //             this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
  //           }

  //           if (colaborador[i].tbl_comida_reclamada_id == null) {
  //             this.listaComidasHoy.estatus_reclamo = 0;
  //             this.banderaComidaDisponible = true;
  //             } else {
  //             this.listaComidasHoy.estatus_reclamo = 1;
  //           }
  //         } else {
  //           if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
  //             this.listaComidasHoy.estatus_reclamo = 2;
  //           } else {
  //             this.listaComidasHoy.estatus_reclamo = 3;
  //           }
  //         }
  //         this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
  //         // this.listaComidasHoy.horario = [colaborador[i].h_miercoles_i.hours.toString().padStart(2, '0'), colaborador[i].h_miercoles_i.minutes.toString().padStart(2, '0'), colaborador[i].h_miercoles_i.seconds.toString().padStart(2, '0')].join(':')
  //         //   + " a " + [colaborador[i].h_miercoles_f.hours.toString().padStart(2, '0'), colaborador[i].h_miercoles_f.minutes.toString().padStart(2, '0'), colaborador[i].h_miercoles_f.seconds.toString().padStart(2, '0')].join(':')
  //         //   + " hrs.";

  //         if (parseInt(horaActual) >= 0 && parseInt(horaActual) <= 4) {
  // this.listaComidasHoy.horario = [colaborador[i].h_martes_i.hours.toString().padStart(2, '0'), colaborador[i].h_martes_i.minutes.toString().padStart(2, '0'), colaborador[i].h_martes_i.seconds.toString().padStart(2, '0')].join(':')
  //           + " a " + [colaborador[i].h_martes_f.hours.toString().padStart(2, '0'), colaborador[i].h_martes_f.minutes.toString().padStart(2, '0'), colaborador[i].h_martes_f.seconds.toString().padStart(2, '0')].join(':')
  //           + " hrs.";
  //                 } else {
  // this.listaComidasHoy.horario = [colaborador[i].h_miercoles_i.hours.toString().padStart(2, '0'), colaborador[i].h_miercoles_i.minutes.toString().padStart(2, '0'), colaborador[i].h_miercoles_i.seconds.toString().padStart(2, '0')].join(':')
  //           + " a " + [colaborador[i].h_miercoles_f.hours.toString().padStart(2, '0'), colaborador[i].h_miercoles_f.minutes.toString().padStart(2, '0'), colaborador[i].h_miercoles_f.seconds.toString().padStart(2, '0')].join(':')
  //           + " hrs.";            }
            
  //         this.arrlistaComidasHoy.push(this.listaComidasHoy);
  //       }
    } 
      break;
      case 4: 
      for (var i = 0; i <= colaborador.length - 1; i++ ) {
        this.listaComidasHoy = new arr_horarios_colaborador;
        this.listaComidasHoyDisponible = new arr_horarios_colaborador_disponible;

        //Nuevo Código
        // Si el dia anterior (miercoles) tiene fecha fin en jueves dene entrar 
        if (colaborador[i].dia_fin_3 == 4) {
          let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, colaborador[i].h_miercoles_i.hours, colaborador[i].h_miercoles_i.minutes, colaborador[i].h_miercoles_i.seconds);
          let h_time_f = new Date(now.getFullYear(), now.getMonth(), now.getDate() , colaborador[i].h_miercoles_f.hours, colaborador[i].h_miercoles_f.minutes, colaborador[i].h_miercoles_f.seconds);

          if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
            this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
            this.horarioDisponible = true;
            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
            }

            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.listaComidasHoy.estatus_reclamo = 0;
              this.banderaComidaDisponible = true;
              } else {
              this.listaComidasHoy.estatus_reclamo = 1;
            }
          } else {
            if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
              this.listaComidasHoy.estatus_reclamo = 2;
            } else {
              this.listaComidasHoy.estatus_reclamo = 3;
            }
          }
          this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
          this.listaComidasHoy.horario = [colaborador[i].h_miercoles_i.hours.toString().padStart(2, '0'), colaborador[i].h_miercoles_i.minutes.toString().padStart(2, '0'), colaborador[i].h_miercoles_i.seconds.toString().padStart(2, '0')].join(':')
            + " a " + [colaborador[i].h_miercoles_f.hours.toString().padStart(2, '0'), colaborador[i].h_miercoles_f.minutes.toString().padStart(2, '0'), colaborador[i].h_miercoles_f.seconds.toString().padStart(2, '0')].join(':')
            + " hrs.";

          this.arrlistaComidasHoy.push(this.listaComidasHoy);
        } else {
          if (colaborador[i].jueves == true) {

            let _diafin;
            if (colaborador[i].dia_fin_4 == 0) {
              _diafin =  now.getDate() + (3);       
            } else {
              _diafin =  now.getDate() + (colaborador[i].dia_fin_4 - 4);       
            }
  
            let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate(), colaborador[i].h_jueves_i.hours, colaborador[i].h_jueves_i.minutes, colaborador[i].h_jueves_i.seconds);
            let h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_jueves_f.hours, colaborador[i].h_jueves_f.minutes, colaborador[i].h_jueves_f.seconds);
  
            if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
              this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
              this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
              this.horarioDisponible = true;
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
              }
  
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.listaComidasHoy.estatus_reclamo = 0;
                this.banderaComidaDisponible = true;
                } else {
                this.listaComidasHoy.estatus_reclamo = 1;
              }
            } else {
              if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
                this.listaComidasHoy.estatus_reclamo = 2;
              } else {
                this.listaComidasHoy.estatus_reclamo = 3;
              }
            }
            this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoy.horario = [colaborador[i].h_jueves_i.hours.toString().padStart(2, '0'), colaborador[i].h_jueves_i.minutes.toString().padStart(2, '0'), colaborador[i].h_jueves_i.seconds.toString().padStart(2, '0')].join(':')
              + " a " + [colaborador[i].h_jueves_f.hours.toString().padStart(2, '0'), colaborador[i].h_jueves_f.minutes.toString().padStart(2, '0'), colaborador[i].h_jueves_f.seconds.toString().padStart(2, '0')].join(':')
              + " hrs.";
  
            this.arrlistaComidasHoy.push(this.listaComidasHoy);
          }
        }
        //Nuevo Código

      
    } 

      break;
      case 5: 
      for (var i = 0; i <= colaborador.length - 1; i++ ) {
        this.listaComidasHoy = new arr_horarios_colaborador;
        this.listaComidasHoyDisponible = new arr_horarios_colaborador_disponible;

           //Nuevo Código
        // Si el dia anterior (jueves) tiene fecha fin en viernes dene entrar 
        if (colaborador[i].dia_fin_4 == 5) {
          let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, colaborador[i].h_jueves_i.hours, colaborador[i].h_jueves_i.minutes, colaborador[i].h_jueves_i.seconds);
          let h_time_f = new Date(now.getFullYear(), now.getMonth(), now.getDate() , colaborador[i].h_jueves_f.hours, colaborador[i].h_jueves_f.minutes, colaborador[i].h_jueves_f.seconds);

          if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
            this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
            this.horarioDisponible = true;
            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
            }

            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.listaComidasHoy.estatus_reclamo = 0;
              this.banderaComidaDisponible = true;
              } else {
              this.listaComidasHoy.estatus_reclamo = 1;
            }
          } else {
            if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
              this.listaComidasHoy.estatus_reclamo = 2;
            } else {
              this.listaComidasHoy.estatus_reclamo = 3;
            }
          }
          this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
          this.listaComidasHoy.horario = [colaborador[i].h_jueves_i.hours.toString().padStart(2, '0'), colaborador[i].h_jueves_i.minutes.toString().padStart(2, '0'), colaborador[i].h_jueves_i.seconds.toString().padStart(2, '0')].join(':')
            + " a " + [colaborador[i].h_jueves_f.hours.toString().padStart(2, '0'), colaborador[i].h_jueves_f.minutes.toString().padStart(2, '0'), colaborador[i].h_jueves_f.seconds.toString().padStart(2, '0')].join(':')
            + " hrs.";

          this.arrlistaComidasHoy.push(this.listaComidasHoy);
        } else {
          if (colaborador[i].viernes == true) {

            let _diafin;
            if (colaborador[i].dia_fin_5 == 0) {
              _diafin =  now.getDate() + (2);       
            } else {
              _diafin =  now.getDate() + (colaborador[i].dia_fin_5 - 5);       
            }
  
            let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate(), colaborador[i].h_viernes_i.hours, colaborador[i].h_viernes_i.minutes, colaborador[i].h_viernes_i.seconds);
            let h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_viernes_f.hours, colaborador[i].h_viernes_f.minutes, colaborador[i].h_viernes_f.seconds);
  
            if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
              this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
              this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
              this.horarioDisponible = true;
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
              }
  
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.listaComidasHoy.estatus_reclamo = 0;
                this.banderaComidaDisponible = true;
                } else {
                this.listaComidasHoy.estatus_reclamo = 1;
              }
            } else {
              if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
                this.listaComidasHoy.estatus_reclamo = 2;
              } else {
                this.listaComidasHoy.estatus_reclamo = 3;
              }
            }
            this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoy.horario = [colaborador[i].h_viernes_i.hours.toString().padStart(2, '0'), colaborador[i].h_viernes_i.minutes.toString().padStart(2, '0'), colaborador[i].h_viernes_i.seconds.toString().padStart(2, '0')].join(':')
              + " a " + [colaborador[i].h_viernes_f.hours.toString().padStart(2, '0'), colaborador[i].h_viernes_f.minutes.toString().padStart(2, '0'), colaborador[i].h_viernes_f.seconds.toString().padStart(2, '0')].join(':')
              + " hrs.";
  
            this.arrlistaComidasHoy.push(this.listaComidasHoy);
          }
        }
        //Nuevo Código



    }       
    break;
      case 6: 
      for (var i = 0; i <= colaborador.length - 1; i++ ) {
        this.listaComidasHoy = new arr_horarios_colaborador;
        this.listaComidasHoyDisponible = new arr_horarios_colaborador_disponible;
        
           //Nuevo Código
        // Si el dia anterior (viernes) tiene fecha fin en sábado debe entrar 
        if (colaborador[i].dia_fin_5 == 6) {
          let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, colaborador[i].h_viernes_i.hours, colaborador[i].h_viernes_i.minutes, colaborador[i].h_viernes_i.seconds);
          let h_time_f = new Date(now.getFullYear(), now.getMonth(), now.getDate() , colaborador[i].h_viernes_f.hours, colaborador[i].h_viernes_f.minutes, colaborador[i].h_viernes_f.seconds);

          if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
            this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
            this.horarioDisponible = true;
            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
            }

            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.listaComidasHoy.estatus_reclamo = 0;
              this.banderaComidaDisponible = true;
              } else {
              this.listaComidasHoy.estatus_reclamo = 1;
            }
          } else {
            if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
              this.listaComidasHoy.estatus_reclamo = 2;
            } else {
              this.listaComidasHoy.estatus_reclamo = 3;
            }
          }
          this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
          this.listaComidasHoy.horario = [colaborador[i].h_viernes_i.hours.toString().padStart(2, '0'), colaborador[i].h_viernes_i.minutes.toString().padStart(2, '0'), colaborador[i].h_viernes_i.seconds.toString().padStart(2, '0')].join(':')
            + " a " + [colaborador[i].h_viernes_f.hours.toString().padStart(2, '0'), colaborador[i].h_viernes_f.minutes.toString().padStart(2, '0'), colaborador[i].h_viernes_f.seconds.toString().padStart(2, '0')].join(':')
            + " hrs.";

          this.arrlistaComidasHoy.push(this.listaComidasHoy);
        } else {
          if (colaborador[i].sabado == true) {

            let _diafin;
            if (colaborador[i].dia_fin_6 == 0) {
              _diafin =  now.getDate() + (1);       
            } else {
              _diafin =  now.getDate() + (colaborador[i].dia_fin_6 - 6);       
            }
  
            let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate(), colaborador[i].h_sabado_i.hours, colaborador[i].h_sabado_i.minutes, colaborador[i].h_sabado_i.seconds);
            let h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_sabado_f.hours, colaborador[i].h_sabado_f.minutes, colaborador[i].h_sabado_f.seconds);
  
            if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
              this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
              this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
              this.horarioDisponible = true;
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
              }
  
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.listaComidasHoy.estatus_reclamo = 0;
                this.banderaComidaDisponible = true;
                } else {
                this.listaComidasHoy.estatus_reclamo = 1;
              }
            } else {
              if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
                this.listaComidasHoy.estatus_reclamo = 2;
              } else {
                this.listaComidasHoy.estatus_reclamo = 3;
              }
            }
            this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoy.horario = [colaborador[i].h_sabado_i.hours.toString().padStart(2, '0'), colaborador[i].h_sabado_i.minutes.toString().padStart(2, '0'), colaborador[i].h_sabado_i.seconds.toString().padStart(2, '0')].join(':')
              + " a " + [colaborador[i].h_sabado_f.hours.toString().padStart(2, '0'), colaborador[i].h_sabado_f.minutes.toString().padStart(2, '0'), colaborador[i].h_sabado_f.seconds.toString().padStart(2, '0')].join(':')
              + " hrs.";
  
            this.arrlistaComidasHoy.push(this.listaComidasHoy);
          }
        }
        //Nuevo Código


      
    } 
      break;
      case 0: 
      for (var i = 0; i <= colaborador.length - 1; i++ ) {
        this.listaComidasHoy = new arr_horarios_colaborador;
        this.listaComidasHoyDisponible = new arr_horarios_colaborador_disponible;

               //Nuevo Código
        // Si el dia anterior (viernes) tiene fecha fin en sábado debe entrar 
        if (colaborador[i].dia_fin_6 == 0) {
          let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, colaborador[i].h_sabado_i.hours, colaborador[i].h_sabado_i.minutes, colaborador[i].h_sabado_i.seconds);
          let h_time_f = new Date(now.getFullYear(), now.getMonth(), now.getDate() , colaborador[i].h_sabado_f.hours, colaborador[i].h_sabado_f.minutes, colaborador[i].h_sabado_f.seconds);

          if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
            this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
            this.horarioDisponible = true;
            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
            }

            if (colaborador[i].tbl_comida_reclamada_id == null) {
              this.listaComidasHoy.estatus_reclamo = 0;
              this.banderaComidaDisponible = true;
              } else {
              this.listaComidasHoy.estatus_reclamo = 1;
            }
          } else {
            if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
              this.listaComidasHoy.estatus_reclamo = 2;
            } else {
              this.listaComidasHoy.estatus_reclamo = 3;
            }
          }
          this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
          this.listaComidasHoy.horario = [colaborador[i].h_sabado_i.hours.toString().padStart(2, '0'), colaborador[i].h_sabado_i.minutes.toString().padStart(2, '0'), colaborador[i].h_sabado_i.seconds.toString().padStart(2, '0')].join(':')
            + " a " + [colaborador[i].h_sabado_f.hours.toString().padStart(2, '0'), colaborador[i].h_sabado_f.minutes.toString().padStart(2, '0'), colaborador[i].h_sabado_f.seconds.toString().padStart(2, '0')].join(':')
            + " hrs.";

          this.arrlistaComidasHoy.push(this.listaComidasHoy);
        } else {
          if (colaborador[i].domingo == true) {

            let _diafin;
            if (colaborador[i].dia_fin_0 == 0) {
              _diafin =  now.getDate();       
            } else {
              _diafin =  now.getDate() + (colaborador[i].dia_fin_0);       
            }
  
            let h_time_i = new Date(now.getFullYear(), now.getMonth(), now.getDate(), colaborador[i].h_domingo_i.hours, colaborador[i].h_domingo_i.minutes, colaborador[i].h_domingo_i.seconds);
            let h_time_f = new Date(now.getFullYear(), now.getMonth(), _diafin, colaborador[i].h_domingo_f.hours, colaborador[i].h_domingo_f.minutes, colaborador[i].h_domingo_f.seconds);
  
            if (now.getTime() > h_time_i.getTime() && now.getTime() < h_time_f.getTime()) {
              this.listaComidasHoyDisponible.nombre_comida = colaborador[i].nombre_tipo_comida;
              this.listaComidasHoyDisponible.rel_usuario_horario_id = colaborador[i].rel_usuario_horario_id;
              this.horarioDisponible = true;
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.arrlistaComidasHoyDisponible.push(this.listaComidasHoyDisponible);
              }
  
              if (colaborador[i].tbl_comida_reclamada_id == null) {
                this.listaComidasHoy.estatus_reclamo = 0;
                this.banderaComidaDisponible = true;
                } else {
                this.listaComidasHoy.estatus_reclamo = 1;
              }
            } else {
              if (now.getTime() > h_time_i.getTime() && now.getTime() > h_time_f.getTime()) {
                this.listaComidasHoy.estatus_reclamo = 2;
              } else {
                this.listaComidasHoy.estatus_reclamo = 3;
              }
            }
            this.listaComidasHoy.nombre_comida = colaborador[i].nombre_tipo_comida;
            this.listaComidasHoy.horario = [colaborador[i].h_domingo_i.hours.toString().padStart(2, '0'), colaborador[i].h_domingo_i.minutes.toString().padStart(2, '0'), colaborador[i].h_domingo_i.seconds.toString().padStart(2, '0')].join(':')
              + " a " + [colaborador[i].h_domingo_f.hours.toString().padStart(2, '0'), colaborador[i].h_domingo_f.minutes.toString().padStart(2, '0'), colaborador[i].h_domingo_f.seconds.toString().padStart(2, '0')].join(':')
              + " hrs.";
  
            this.arrlistaComidasHoy.push(this.listaComidasHoy);
          }
        }
        //Nuevo Código

    
    } 
      break;
      default:

    }
  }

  }

  reclamarComidaModal(rel_usuario_horario_id: number, _nombreComida: string, _infoColaborador: info_usuario_modelo_response) {

    if (this._requiere_pin == true) {
      this.openModalValidaPin(rel_usuario_horario_id);
    } else {
      this.reclamarComida(rel_usuario_horario_id);
    //  this.imprimirTicket(_nombreComida,_infoColaborador);
    }

  }

  reclamarComida(rel_usuario_horario_id) {
    if (this._requiere_pin == true) {
      if (this._pin == this.formGroupCodigoPin.value.p_pin) {
        let model = new Set_reclamar_usuario_horario_modelo_request;
        model.p_rel_usuario_horario_id = this._rel_usuario_horario_id;
        this.operacionRespuesta.EstaEjecutando = true;
        this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/InfoUsuario/PostReclamarUsuarioHorario")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.getColaboradorInfo();
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.modalValPin.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      } else {
        this.openMensajes('El código pin no correcto', true, '');
      }
    } else {
      let model = new Set_reclamar_usuario_horario_modelo_request;
      model.p_rel_usuario_horario_id = rel_usuario_horario_id;
      this.operacionRespuesta.EstaEjecutando = true;
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/InfoUsuario/PostReclamarUsuarioHorario")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.getColaboradorInfo();
            this.openMensajes(tempdate.response[0].msg, false, '');
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }


  }

  imprimirTicket(_nombreComida: string, _infoColaborador: info_usuario_modelo_response) {
   // Codificar los valores antes de agregarlos a la URL
   const nombreComensalCodificado = btoa(encodeURIComponent(_infoColaborador.nombre + ' ' + _infoColaborador.apellido_paterno + ' ' + _infoColaborador.apellido_materno + '_' + _nombreComida));
   // URL con los parámetros codificados
   const url = `/ticket/${nombreComensalCodificado}`;

        const nuevaPestana = window.open(url, '_blank');
       
    
  }
  nuevaConsulta() {
    this.showInfo = false; 
    this._numero_empleado = null;
  }

  consultaImagenPerfil(nombreImg: string ){
    this.imagenPrevia = "";
    // this.esEditable = false;
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/InfoUsuario/GetImagenPerfil?imgName=" + nombreImg)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].nombre != "") {
            this.imagenPrevia = "data:image/jpeg;base64," + tempdate.response[0].nombre;
            this.nuevaImagenAvatar = true;
          } else {
            this.nuevaImagenAvatar = false;
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.nuevaImagenAvatar = false;
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );  
  }
  openMensajes(Mensaje: string, Error: boolean,titulo:string) {
    // this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, {ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg = this.modalService.open(this.modalMensaje, {ariaLabelledBy: "modal-basic-title" });
    this.mensajesExito = [];
    this.mensajesError = [];
    // this.mensaje = Mensaje;
    if (Error) {
      this.showErrors = true;
      this.showExitos = false;

    } else {
      this.showErrors = false;
      this.showExitos = true;
    }

    this.mensajeTitulo = 'Valida comida';
  
    if (Error) {
      this.showErrors = true;
      this.mensajesError.push(Mensaje);
    } else {
      this.showExitos = true;
      this.mensajesExito.push(Mensaje);
    }
  }

  openModalValidaPin(rel_usuario_horario_id) {
    this._rel_usuario_horario_id = rel_usuario_horario_id;
    this.formGroupCodigoPin.reset({p_pin:''});
    this.modalValPin = this.modalService.open(this.modalValidaPin, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static'  });
  }
}
