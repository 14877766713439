// const apiURL = 'https://localhost:44394/';
// const apiURL = 'https://api.sesverregional.com/';
const apiURL = 'https://api-issstetoluca.grupobersa.com/';
export class ServiciosRutas {

  serviciosOperaciones = apiURL + 'Operaciones';
  // serviciosCatalogos =   apiURL + 'Catalogos';
  // fileUrl = fileUrl;
}

